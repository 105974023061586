import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const BackendDeveloper = () => {
  const job: JobType = {
    id: '1',
    title: "Senior Backend Engineer (Kotlin)",
    location: {
        name: "Poznań, Poland"
    },
    metadata: [
      {
        value: {
          min_value: "22500",
          max_value: "37000",
          unit: "PLN"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    emailApply: true,
    ogImage: "https://jobs.fourthwall.com/images/og/backend.png",
    content: `
      <div class="article">
        <div>
          <p><strong>Job Summary</strong></p>
          <p><span style="font-weight: 400;">We are looking for a highly skilled Senior Backend Engineer who excels at crafting code for spring- based Kotlin web apps. You will work alongside our exceptional team of 40+ engineers with an average industry experience of over 10 years. Work remotely or from our beautiful office in Poznań, or some mix of the two - it's up to you.</span></p>
        </div>

        <div>
          <p><strong>What we expect:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Expert knowledge of <strong data-renderer-mark="true">Kotlin</strong> or <strong data-renderer-mark="true">Java</strong>,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Extensive experience with relational databases,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience in building and scaling the architecture of large applications and ensuring the security of large applications,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to seamlessly navigate and prioritize, showcasing your knack for strategic innovation and self-directed project management,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Soft skills, openness to conversations, sharing knowledge, and contributing to the team's growth,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">English language proficiency to allow accessible communication both in writing and speech,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"></span>Experience in working with tools such as Github CoPilot, GitLab, Slack, JIRA,</li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Knowledge of modern frontend stack, Kubernetes, GCP, service mesh, NoSQL, Ruby, and Node.js, as well as passions related to 3D and photography, will be an additional advantage.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Perks:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Salary 22 500 - 37 000 PLN (paid in USD, + VAT 0% as you'll be exporting your services to the US),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity in our US-based company,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Remote work, flexible working hours, and access to our modern and comfortable office in Poznań (Jeżyce),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">B2B agreement for an indefinite period with 26 days of paid vacation per year, health-related absence policy,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An exciting and demanding project that you have a real influence on,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Work in a team of experienced experts and amazing people,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">The latest standards of used tools - current and supported frameworks, without working on outdated code,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Employer-paid perks (MultiSport, LUX MED + dental care, accounting support, educational budget, English lessons, team integrations, four months parental allowance),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">MacBook Pro and any other equipment tailored to your needs.</span></li>
          </ul>
        </div>
      
        <div>
          <p><strong>Technologies in a nutshell:</strong></p>
          <p><span style="font-weight: 400;">Kotlin, Spring, PostgreSQL, Ruby on Rails, Sorbet, OpenApi, Google Cloud Platform, Kubernetes, Service Mesh, Gitflow, Craftsmanship, DDD, Twelve-Factor Apps.</span></p>
        </div>

        <div>
          <p><strong>About us:</strong></p>
          <p><span style="font-weight: 400;">Fourthwall gives creators the freedom to focus on creating. Over 100,000 creators are using Fourthwall to power their shops and memberships.</span></p>
          <p><span style="font-weight: 400;">We enable creators (YouTubers, Instagrammers, Podcasters, etc.- anyone with a large online audience) to make money by opening beautiful, fully customized online shops where they can sell merchandise, accept donations, and offer memberships. Our mission is to make the money-making side of content creation easy and let creators focus on what matters: creating great content and interacting with their fans.</span></p>
          <p><span style="font-weight: 400;">Our culture is built around putting our customers, the creators, first. If you are talented, hardworking, and love helping others, we would love to talk to you about joining our team!</span></p>
          <p><span style="font-weight: 400;">By choosing our offer, you can join a team of experts in application architecture, programming languages (Kotlin and Ruby enthusiasts), and, most importantly, extensive e-commerce business experience.</span></p>
          <p><span style="font-weight: 400;">If you want to learn more about us, and how we work, you can peek at <a href="https://jobs.fourthwall.com" target="_blank">jobs.fourthwall.com</a>. We also suggest that you get to know our <a href="https://cdn.fourthwall.com/public/fourthwall/Fourthwall%20Values.pdf" target="_blank">Company Values</a>.</span></p>
        </div>

        <div>
          <p><strong>Recruitment process:</strong></p>
          <ol>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Sending CV + initial phone call (20 - 45 minutes)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Recruitment task carried out at a time selected by you (remotely), we will pay PLN 650 net for your time (regardless of the interview result)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Meeting (in person or remotely) at the Fourthwall HQ with our team (w/2-3 engineers from our team) (1-2 hours)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Your and our decision within three business days</span></li>
          </ol>
        </div>

        <div>
          <p><span style="font-weight: 400;"><strong>It's also worth noting that we encourage anyone who may be interested to apply, even if you don't meet 100% of the qualifications above.</strong> We may have overlooked, under-counted, or over-indexed on some of these qualifications. So, please — apply. You know what they say about missing shots...</span></p>
          <p><span style="font-weight: 400;"><em data-renderer-mark="true">We are an equal-opportunity employer. We value a diverse workforce and an inclusive culture. We encourage applications from all qualified individuals without regard to race, color, religion, gender, sexual orientation, gender identity or expression, age, national origin, marital status, disability, and veteran status.</em></span></p>
        </div>

      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default BackendDeveloper